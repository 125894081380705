import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { StockLocationPage } from '../pages/StockLocationPage';
import { StockPoolPage } from '../pages/StockPoolPage';
import { StockLocationRankingPage } from '../pages/StockLocationRankingPage';
import { StorePage } from '../pages/StorePage';
import { LeadtimeMatrixPage } from '../pages/LeadtimeMatrixPage';
import { UserProfilePage } from '../pages/UserProfilePage';
import { LoginPage } from '../pages/LoginPage';
import { NavigationController } from './NavigationController';
import { MuiThemeProvider } from "@material-ui/core/styles"
import { MainTheme } from '../theme/CelinePalette';
import { ThresholdPage } from '../pages/ThresholdPage';
import { LoginCallback, SecureRoute } from '../../../../okta-react';
import { PageNotFound } from '../pages/PageNotFound';
import { QuotaPage } from '../pages/QuotaPage';
import { CCZonePage } from "../pages/CCZonePage";
import { DefaultPPSPage } from "../pages/DefaultPPSPage";
import {StockPoolAuditPage} from "../pages/StockPoolAuditPage";
import {DefaultPPSAuditPage} from "../pages/DefaultPPSAuditPage";
import {SkuAvailabilityPage} from "../pages/SkuAvailabilityPage";
import {DataSetRequestPage} from "../pages/DataSetRequestPage";
import {StockLocationsAuditPage} from "../pages/StockLocationsAuditPage";
import {ThresholdAuditPage} from "../pages/ThresholdAuditPage";
import {QuotaAuditPage} from "../pages/QuotaAuditPage";
import {QuotaSkuAuditPage} from "../pages/QuotaSkuAuditPage";
import {CountryDayOffPage} from "../pages/CountryDaysOffPage";
import {TransporterDaysOffByCountryPage} from "../pages/TransporterDaysOffByCountryPage";
import {TransporterDaysOffWeekPage} from "../pages/TransporterDaysOffWeekPage";
import {TransferDelayPage} from "../pages/TransferDelayPage";
import {ShipmentMatrixPage} from "../pages/ShipmentMatrixPage";
import {PromiseDateMarginPage} from "../pages/PromiseDateMarginPage";
import {DefaultLeadTimePage} from "../pages/DefaultLeadTimePage";
import {SubzonePage} from "../pages/SubzonePage";
import {PreorderConfigPage} from "../pages/PreorderConfigPage";
import {DeliveryMethodEligibilityPage} from "../pages/DeliveryMethodEligibilityPage";
import {CCZoneAuditPage} from "../pages/CCZoneAuditPage";
import {PreorderConfigAuditPage} from "../pages/PreorderConfigAuditPage";
import {DeliveryMethodEligibilityAuditPage} from "../pages/DeliveryMethodEligibilityAuditPage";
import {SubzoneAuditPage} from "../pages/SubzoneAuditPage";
import {SubzoneCountryAuditPage} from "../pages/SubzoneCountryAuditPage";
import {SubzoneStateAuditPage} from "../pages/SubzoneStateAuditPage";

export const Routes = {
    stockLocationPage: '/stock-locations',
    stockPoolPage: '/stock-pool',
    storePage: '/store-page',
    stockLocationRankingPage: '/stock-location-ranking',
    leadtimeMatrixPage: '/leadtime-matrix',
    userProfilePage: '/profile',
    loginPage: '/',
    thresholdPage: '/threshold',
    quotaPage: '/quota',
    cCZonePage: '/cc-zone',
    ccZoneAuditPage: '/cc-zone-audit',
    preorderConfigAuditPage: '/mto-config-audit',
    deliveryMethodEligibilityAuditPage: '/delivery-method-eligibility-audit',
    defaultPpsPage: '/default-pps',
    stockPoolAuditPage: '/stock-pool-audit',
    defaultPPSAuditPage: '/default-pps-audit',
    skuAvailabilityPage: '/sku-availability',
    dataSetRequestPage: '/dataset-request',
    stockLocationsAuditPage: '/stock-locations-audit',
    thresholdAuditPage: '/threshold-audit',
    quotaAuditPage: '/quota-audit',
    quotaSkuAuditPage: '/quota-sku-audit',
    countryDaysOffPage: '/days-off-by-country',
    preorderConfigPage: '/mto-config',
    deliveryMethodEligibilityPage: '/delivery-method-eligibility',
    transporterByCountryPage: '/transporter-by-country',
    transporterDaysOffWeek: '/transporter-days-off-week',
    transferDelay: '/transfer-delay',
    shipmentMatrix: '/shipment-matrix',
    promiseDateMargin: '/promise-date-margin',
    defaultLeadTime: '/default-lead-time',
    subzone: '/subzone',
    subzoneAudit: '/subzone-audit',
    subzoneCountryAudit: '/subzone-country-audit',
    subzoneStateAudit: '/subzone-state-audit',
    allPages: '/**'
};

export const ProtectedAppRouter = () => {
        return (
            <>
                <SecureRoute exact path={Routes.stockPoolPage} component={StockPoolPage} />
                <SecureRoute exact path={Routes.stockLocationPage} component={StockLocationPage} />
                <SecureRoute exact path={Routes.stockLocationRankingPage} component={StockLocationRankingPage} />
                <SecureRoute exact path={Routes.storePage} component={StorePage} />
                <SecureRoute exact path={Routes.leadtimeMatrixPage} component={LeadtimeMatrixPage} />
                <SecureRoute exact path={Routes.userProfilePage} component={UserProfilePage} />
                <SecureRoute exact path={Routes.thresholdPage} component={ThresholdPage} />
                <SecureRoute exact path={Routes.quotaPage} component={QuotaPage} />
                <SecureRoute exact path={Routes.cCZonePage} component={CCZonePage} />
                <SecureRoute exact path={Routes.ccZoneAuditPage} component={CCZoneAuditPage} />
                <SecureRoute exact path={Routes.preorderConfigAuditPage} component={PreorderConfigAuditPage} />
                <SecureRoute exact path={Routes.deliveryMethodEligibilityAuditPage} component={DeliveryMethodEligibilityAuditPage} />
                <SecureRoute exact path={Routes.defaultPpsPage} component={DefaultPPSPage} />
                <SecureRoute exact path={Routes.stockPoolAuditPage} component={StockPoolAuditPage} />
                <SecureRoute exact path={Routes.defaultPPSAuditPage} component={DefaultPPSAuditPage} />
                <SecureRoute exact path={Routes.skuAvailabilityPage} component={SkuAvailabilityPage} />
                <SecureRoute exact path={Routes.dataSetRequestPage} component={DataSetRequestPage} />
                <SecureRoute exact path={Routes.stockLocationsAuditPage} component={StockLocationsAuditPage} />
                <SecureRoute exact path={Routes.thresholdAuditPage} component={ThresholdAuditPage} />
                <SecureRoute exact path={Routes.quotaAuditPage} component={QuotaAuditPage} />
                <SecureRoute exact path={Routes.quotaSkuAuditPage} component={QuotaSkuAuditPage} />
                <SecureRoute exact path={Routes.countryDaysOffPage} component={CountryDayOffPage} />
                <SecureRoute exact path={Routes.transporterByCountryPage} component={TransporterDaysOffByCountryPage} />
                <SecureRoute exact path={Routes.transporterDaysOffWeek} component={TransporterDaysOffWeekPage} />
                <SecureRoute exact path={Routes.transferDelay} component={TransferDelayPage} />
                <SecureRoute exact path={Routes.shipmentMatrix} component={ShipmentMatrixPage} />
                <SecureRoute exact path={Routes.promiseDateMargin} component={PromiseDateMarginPage} />
                <SecureRoute exact path={Routes.defaultLeadTime} component={DefaultLeadTimePage} />
                <SecureRoute exact path={Routes.subzone} component={SubzonePage} />
                <SecureRoute exact path={Routes.subzoneAudit} component={SubzoneAuditPage} />
                <SecureRoute exact path={Routes.subzoneCountryAudit} component={SubzoneCountryAuditPage} />
                <SecureRoute exact path={Routes.subzoneStateAudit} component={SubzoneStateAuditPage} />
                <SecureRoute exact path={Routes.preorderConfigPage} component={PreorderConfigPage} />
                <SecureRoute exact path={Routes.deliveryMethodEligibilityPage} component={DeliveryMethodEligibilityPage} />
            </>
    );
};



const routesList = [
    {
        path: Routes.stockPoolPage,
        component: StockPoolPage
    },
    {
        path: Routes.stockLocationPage,
        component: StockLocationPage
    },
    {
        path: Routes.stockLocationRankingPage,
        component: StockLocationRankingPage
    },
    {
        path: Routes.storePage,
        component: StorePage
    },
    {
        path: Routes.leadtimeMatrixPage,
        component: LeadtimeMatrixPage
    },
    {
        path: Routes.userProfilePage,
        component: UserProfilePage
    },
    {
        path: Routes.thresholdPage,
        component: ThresholdPage
    },
    {
        path: Routes.quotaPage,
        component: QuotaPage
    },
    {
        path: Routes.defaultPpsPage,
        component: DefaultPPSPage
    },
    {
        path: Routes.cCZonePage,
        component: CCZonePage
    },
    {
        path: Routes.ccZoneAuditPage,
        component: CCZoneAuditPage
    },
    {
        path: Routes.preorderConfigAuditPage,
        component: PreorderConfigAuditPage
    },
    {
        path: Routes.deliveryMethodEligibilityAuditPage,
        component: DeliveryMethodEligibilityAuditPage
    },
    {
        path: Routes.stockPoolAuditPage,
        component: StockPoolAuditPage
    },
    {
        path: Routes.defaultPPSAuditPage,
        component: DefaultPPSAuditPage
    },
    {
        path: Routes.skuAvailabilityPage,
        component: SkuAvailabilityPage
    },
    {
        path: Routes.dataSetRequestPage,
        component: DataSetRequestPage
    },
    {
        path: Routes.stockLocationsAuditPage,
        component: StockLocationsAuditPage
    },
    {
        path: Routes.thresholdAuditPage,
        component: ThresholdAuditPage
    },
    {
        path: Routes.quotaAuditPage,
        component: QuotaAuditPage
    },
    {
        path: Routes.quotaSkuAuditPage,
        component: QuotaSkuAuditPage
    },
    {
        path: Routes.countryDaysOffPage,
        component: CountryDayOffPage
    },
    {
        path: Routes.preorderConfigPage,
        component: PreorderConfigPage
    },
    {
        path: Routes.deliveryMethodEligibilityPage,
        component: DeliveryMethodEligibilityPage
    },
    {
        path: Routes.transporterByCountryPage,
        component: TransporterDaysOffByCountryPage
    },
    {
        path: Routes.transporterDaysOffWeek,
        component: TransporterDaysOffWeekPage
    },
    {
        path: Routes.transferDelay,
        component: TransferDelayPage
    },
    {
        path: Routes.shipmentMatrix,
        component: ShipmentMatrixPage
    },
    {
        path: Routes.promiseDateMargin,
        component: PromiseDateMarginPage
    },
    {
        path: Routes.defaultLeadTime,
        component: DefaultLeadTimePage
    },
    {
        path: Routes.subzone,
        component: SubzonePage
    },
    {
        path: Routes.subzoneAudit,
        component: SubzoneAuditPage
    },
    {
        path: Routes.subzoneCountryAudit,
        component: SubzoneCountryAuditPage
    },
    {
        path: Routes.subzoneStateAudit,
        component: SubzoneStateAuditPage
    }
];
export const AppRouter = () => {
    return (
        <MuiThemeProvider theme={MainTheme}>
             <Switch>
                <Route exact path={Routes.loginPage} >
                    <LoginPage />
                </Route>
                {
                    routesList.map(route => {
                        return (<SecureRoute exact {...route}>
                                    <NavigationController />
                                </SecureRoute>)
                    })
                }
                <Route path={process.env.REACT_APP_OKTA_REDIRECT_URI_CALLBACK} exact component={LoginCallback} />
                <Route component={PageNotFound} />
            </Switch>
        </MuiThemeProvider>
    );
};