import React, {useContext, useEffect, useState} from 'react';
import {Page} from '../../presenters/pages/index';
import {AppContext, AppContextSpec} from '../../App';
import {strings} from '../../i18n/strings';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {
    AsyncAutocompleteSelectComponent,
    DropDownValue,
    SupportedReference
} from '../../domain/components/AsyncAutocompleteSelect';
import {grey} from '@material-ui/core/colors';
import {UIButton} from '../../../../ui/inputs/UIButton';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {useOktaAuth} from '../../../../okta-react';
import {
    getSkuAvailability,
    getSkuAvailabilityColumnInformation,
    getStockLastUpdateTime,
    getStockOutsideStockpoolSnapshot,
    getStockPoolReservationValuesSnapshot,
    getStockPoolStockSnapshot
} from "../../domain/repository/SkuRepository";
import {SkuAvailabilityTableStructure} from "../../domain/indexes/SkuAvailabilityIndexes";
import {HeadCell} from "../../../../graphics/tables/data/HeadCell";
import {TableRowData} from "../../../../graphics/tables/data/TableRowData";
import {FormControlLabel, FormGroup, Switch, TableContainer} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import {StockPoolStockSnapshotStructure} from "../../domain/indexes/StockPoolStockSnapshotIndexes";
import {StockOutsideStockpoolSnapshotStructure} from "../../domain/indexes/StockOutsideStockpoolSnapshotIndexes";
import {StockPoolReservationValuesSnapshotStructure} from "../../domain/indexes/StockPoolReservationValuesSnapshot";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rootFilter: {
            display: 'flex',
            flexDirection: 'row',
            '& > *': {
                marginBottom: '1em',
                marginRight: '1em'
            }
        },
        filterPresentation: {
            textDecoration: 'underline',
            fontWeight: 'bold',
            textAlign: 'left'
        },
        cfoStockPresentation: {
            textAlign: 'left'
        },
        sliderRoot: {
            display: 'flex',
            alignItems: 'center',
            margin: '5px 20px 10px 20px'
        },
        waitingSelection: {
            color: grey[600],
            borderRadius: '4px',
            borderColor: grey[600],
            border: 'solid 1px'
        },
        saveAction: {
            margin: '15px 0 21px 0',
            marginLeft: 'auto'
        },
        filterItem: {
            display: 'flex',
            marginBottom: '1em',
            marginRight: '1em',
        },
        skuButtonContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        marginTop20: {
            marginTop: '20px',
            color: grey[600],
            borderRadius: '4px',
            borderColor: grey[600],
            border: 'solid 1px'
        },
        positiveStock: {
            color: grey[500],
        },
        negativeStock: {},
    })
);

const tableFields: Array<string> = [
    'productId',
    'stock',
    'threshold',
    'balanceOfThreshold',
    'maxQuota',
    'balanceOfQuota',
    'stockAvailable'
];

const tableFields2: Array<string> = [
    'stockLocation',
    'stockLocationName',
    'quantity'
];

const tableFields3: Array<string> = [
    'stockLocation',
    'stockLocationName',
    'country',
    'stock'
];

const tableFields4: Array<string> = [
    'stockLocation',
    'stockLocationName',
    'reservationNumber',
    'reservation'
];

const wordingSkuAvailability = strings.page.skuAvailability;

type TableSkuAvailability = SkuAvailabilityTableStructure.SkuAvailability;
type TableStockPoolStockSnapshot = StockPoolStockSnapshotStructure.StockPoolStockSnapshot;
type TableStockOutsideStockpoolSnapshot = StockOutsideStockpoolSnapshotStructure.StockOutsideStockpoolSnapshot;
type TableStockPoolReservationValuesSnapshot = StockPoolReservationValuesSnapshotStructure.StockPoolReservationValuesSnapshot;
type Record = SkuAvailabilityTableStructure.RecordOf<TableSkuAvailability>;
type Record2 = StockPoolStockSnapshotStructure.RecordOf<TableStockPoolStockSnapshot>;
type Record3 = StockOutsideStockpoolSnapshotStructure.RecordOf<TableStockOutsideStockpoolSnapshot>;
type Record4 = StockPoolReservationValuesSnapshotStructure.RecordOf<TableStockPoolReservationValuesSnapshot>;

interface RecordRepresentation extends Record, TableRowData {
};

interface RecordRepresentation2 extends Record2, TableRowData {
};

interface RecordRepresentation3 extends Record3, TableRowData {
};

interface RecordRepresentation4 extends Record4, TableRowData {
};

export const SkuAvailabilityPage = () => {

    const {authState} = useOktaAuth();
    const classes = useStyles();
    const [channelSelection, setChannelSelection] = React.useState(String);
    const [emptyStockReason, setEmptyStockReason] = React.useState(String);
    const [channelChangeSelection, setChannelChangeSelection] = useState<DropDownValue | null | undefined>(null);//used in his child AsyncAutoCompleteSelect, used to disable the Submit button
    const [storeSelection, setStoreSelection] = React.useState(String);
    const [storeChangeSelection, setStoreChangeSelection] = useState<DropDownValue | null | undefined>(null);//used in his child AsyncAutoCompleteSelect, used to disable the Submit button
    const [skuSelection, setSkuSelection] = React.useState(String);
    const [skuChangeSelection, setSkuChangeSelection] = useState<DropDownValue | null | undefined>(null);//used in his child AsyncAutoCompleteSelect, used to disable the Submit button
    const [headCellsConfig, setHeadCellsConfig] = useState<HeadCell<RecordRepresentation>[]>([]);
    const [lastUpdateTime, setLastUpdateTime] = React.useState(String);
    const [tableData, setTableData] = useState<RecordRepresentation[]>([]);
    const [tableData2, setTableData2] = useState<RecordRepresentation2[]>([]);
    const [tableData3, setTableData3] = useState<RecordRepresentation3[]>([]);
    const [tableData4, setTableData4] = useState<RecordRepresentation4[]>([]);
    const [isPositiveStockOutsideStockpool, setIsPositiveStockOutsideStockpool] = useState<boolean>(true);
    const appContext = useContext<AppContextSpec>(AppContext);
    const [preorder, setPreorder] = React.useState(false);

    const allFiltersSet: boolean = channelChangeSelection !== null && storeChangeSelection !== null && skuChangeSelection !== null

    const headCells: HeadCell<RecordRepresentation>[] = tableFields.map((field) => ({
        id: field,
        label: field,
        displayName: field,
        type: "",
        required: true,
        information: "",
        position: 1,
        visible: true,
        editable: true,
        massEditable: false,
        reference: "",
        referenceColumnLabel: "",
        disablePadding: false,
        searchValue: "",
        color: "black"
    }));

    //Get HeadCells to display tab names
    useEffect(() => {
        (async () => {
            try {
                const lastUpdateTimeResponse: string = await getStockLastUpdateTime(authState!);

                const headCellConfigValues: SkuAvailabilityTableStructure.ResponseOfColumn<TableSkuAvailability> = await getSkuAvailabilityColumnInformation<TableSkuAvailability>(authState!);
                headCells.forEach((cell) => {
                    const config = headCellConfigValues[cell.id];
                    cell.id = config.name;
                    cell.label = config.name;
                    cell.type = config.type;
                    cell.required = config.required;
                    cell.information = config.information;
                    cell.position = config.position;
                    cell.visible = config.visible;
                    cell.editable = config.editable;
                    cell.massEditable = config.massEditable;
                    cell.displayName = (config.displayName.length > 0) ? String(config.displayName) : String(config.name);
                });
                setHeadCellsConfig(headCells);
                setLastUpdateTime('Last stock update: ' + lastUpdateTimeResponse + ' (GMT)');
            } catch (error: any) {
                console.log("ERROR: ", error);
            }
        })();
        // eslint-disable-next-line
    }, []);

    const computeReasonOfEmptyStock = (product: SkuAvailabilityTableStructure.RecordOf<SkuAvailabilityTableStructure.SkuAvailability>) => {
        if ((product.stock === 0 && (Number(product.stockInit) - Number(product.resSum)) <= 0)) {
            setEmptyStockReason("This SKU is not available because there is no stock (include substraction of reservations, orders and other pending actions)");
        } else if ((product.stock === 0 && (Number(product.stockInit) - Number(product.resSum) + Number(product.cfoSum)) <= 0)) {
            setEmptyStockReason("This SKU is not available because last items are kept for Outside Stockpool which has negative stock");
        } else if (product.balanceOfQuota === 0 || product.balanceOfQuota === '0') {
            setEmptyStockReason("This SKU is not available because the quota for the week is reached");
        } else if (product.balanceOfThreshold === 0 || product.balanceOfThreshold === '0') {
            setEmptyStockReason("This SKU is not available because the threshold was reached");
        }
    }

    const handleGetAvailabilityBySku = (channel: string, store: string, sku: string, preorder: boolean) => {
        if (channel && store && sku) {
            setChannelSelection("");
            setStoreSelection("")
            setSkuSelection("")

            return getSkuAvailability(authState!, channel, store, sku, preorder)
                .then(data => {
                    const myTableData = data as SkuAvailabilityTableStructure.ResponseOf<TableSkuAvailability>;
                    setTableData(myTableData.products || []);
                    if (myTableData.products && !myTableData.products[0].available) {
                        computeReasonOfEmptyStock(myTableData.products[0])
                    }
                })
                .catch(({message}) => {
                    message && appContext.notifyError(message);
                    setTableData([]);
                })
                .finally(() => appContext.displayLoader(false));
        }
    };

    const handleGetStockPoolStockSnapshot = (channel: string, store: string, sku: string, preorder: boolean) => {
        if (channel && store && sku) {
            setChannelSelection("");
            setStoreSelection("")
            setSkuSelection("")

            return getStockPoolStockSnapshot(authState!, channel, store, sku, preorder)
                .then(data => {
                    const myData = data as RecordRepresentation2[];
                    setTableData2(myData || []);
                })
                .catch(({message}) => {
                    message && appContext.notifyError(message);
                    setTableData2([]);
                })
                .finally(() => appContext.displayLoader(false));
        }
    };

    const handleGetStockOutsideStockpoolSnapshot = (sku: string, channel: string, store: string, preorder: boolean) => {
        if (channel && store && sku) {
            setChannelSelection("");
            setStoreSelection("")
            setSkuSelection("")
            setIsPositiveStockOutsideStockpool(true)

            return getStockOutsideStockpoolSnapshot(authState!, channel, store, sku, preorder)
                .then(data => {
                    const myData = data as RecordRepresentation3[];
                    if (myData.reduce((accumulator, item) => accumulator + item.stock, 0) < 0) {
                        setIsPositiveStockOutsideStockpool(false)
                    }
                    setTableData3(myData || []);
                })
                .catch(({message}) => {
                    message && appContext.notifyError(message);
                    setTableData3([]);
                })
                .finally(() => appContext.displayLoader(false));
        }
    };

    const handleGetStockPoolReservationValuesSnapshot = (sku: string, channel: string, store: string) => {
        if (channel && store && sku) {
            setChannelSelection("");
            setStoreSelection("")
            setSkuSelection("")

            return getStockPoolReservationValuesSnapshot(authState!, channel, store, sku)
                .then(data => {
                    const myData = data as RecordRepresentation4[];
                    setTableData4(myData || []);
                })
                .catch(({message}) => {
                    message && appContext.notifyError(message);
                    setTableData4([]);
                })
                .finally(() => appContext.displayLoader(false));
        }
    };

    const onChannelSelection = (e: React.FocusEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>) => {
        let newChannelValue = e.target.value.trim();
        if (newChannelValue.length > 0 && newChannelValue !== channelSelection) {
            newChannelValue = newChannelValue.split(" ")[0].trim();
            setChannelSelection(newChannelValue);
        }
    };

    const onStoreSelection = (e: React.FocusEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>) => {
        let newStoreValue = e.target.value.trim();
        if (newStoreValue.length > 0 && newStoreValue !== storeSelection) {
            newStoreValue = newStoreValue.split(" ")[0].trim();
            setStoreSelection(newStoreValue);
        }
    };

    const onSkuSelection = (e: React.FocusEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>) => {
        let newSkuValue = e.target.value.trim();
        if (newSkuValue.length > 0 && newSkuValue !== skuSelection) {
            newSkuValue = newSkuValue.split(" ")[0].trim();
            setSkuSelection(newSkuValue);
        }
    };

    //Sku availability
    const tableHeaders = headCellsConfig.map((cell) => (
        <TableCell key={cell.id}
                   style={{color: preorder && (cell.label === 'threshold' || cell.label === 'balanceOfThreshold') ? 'grey' : 'black' || 'inherit'}}>
            <b>{cell.displayName}</b>
        </TableCell>
    ));

    const tableRows = tableData.map((row, index) => (
        <TableRow key={index}>
            {tableFields.map((field) => (
                <TableCell key={field}>{row[field] !== null ? (row[field] !== 0 ? row[field] : '0') : '-'}</TableCell>
            ))}
        </TableRow>
    ));

    //Stock Pool Stock Snapshot Table
    const stockPoolStockSnapshotHeader = [
        {id: 'stockLocation', displayName: 'Stock Location Code'},
        {id: 'stockLocationName', displayName: 'Stock Location Name'},
        {id: 'quantity', displayName: 'Stock'}
    ]

    const tableHeaders2 = stockPoolStockSnapshotHeader.map((cell) => (
        <TableCell key={cell.id}>
            <b>{cell.displayName}</b>
        </TableCell>
    ));

    const tableRows2 = tableData2.map((row, index) => (
        <TableRow key={index}>
            {tableFields2.map((field) => {
                    return <TableCell key={field}>{row[field] !== null ? row[field] : '0'}</TableCell>
                }
            )}
        </TableRow>
    ));

    //Hierarchy Region Order Values Snapshot Table
    const stockOutsideStockpoolSnapshotHeader = [
        {id: 'stockLocation', displayName: 'Stock Location Code'},
        {id: 'stockLocationName', displayName: 'Stock Location Name'},
        {id: 'country', displayName: 'Country'},
        {id: 'stock', displayName: 'Stock'}
    ]

    const tableHeaders3 = stockOutsideStockpoolSnapshotHeader.map((cell) => (
        <TableCell
            className={isPositiveStockOutsideStockpool ? classes.positiveStock : classes.negativeStock}
            key={cell.id}>
            <b>{cell.displayName}</b>
        </TableCell>
    ));

    const tableRows3 = tableData3.map((row, index) => (
        <TableRow key={index}>
            {tableFields3.map((field) => {
                    return <TableCell
                        className={isPositiveStockOutsideStockpool ? classes.positiveStock : classes.negativeStock}
                        key={field}>
                        {row[field] !== null ? row[field] : '0'}
                    </TableCell>
                }
            )}
        </TableRow>
    ));

    //Stock Pool Reservation Values Snapshot Table
    const stockPoolReservationValuesSnapshotHeader = [
        {id: 'stockLocation', displayName: 'Stock Location Code'},
        {id: 'stockLocationName', displayName: 'Stock Location Name'},
        {id: 'reservationNumber', displayName: 'Reservation Number'},
        {id: 'reservation', displayName: 'Reservation'}
    ]

    const tableHeaders4 = stockPoolReservationValuesSnapshotHeader.map((cell) => (
        <TableCell key={cell.id}>
            <b>{cell.displayName}</b>
        </TableCell>
    ));

    const tableRows4 = tableData4.map((row, index) => (
        <TableRow key={index}>
            {tableFields4.map((field) => {
                    return <TableCell key={field}>{row[field] !== null ? row[field] : '0'}</TableCell>
                }
            )}
        </TableRow>
    ));

    const changeCheckedState = () => {
        setPreorder(!preorder);
        if (tableData !== []) {
            findAvailability(!preorder);
        }
    }

    const findAvailability = (isPreorder: boolean) => {
        setEmptyStockReason('')
        const channel = channelChangeSelection?.value.split(" ")[0].trim() ?? "";
        const store = storeChangeSelection?.value.split(" ")[0].trim() ?? "";
        const sku = skuChangeSelection?.value.split(" ")[0].trim() ?? "";

        if (channel && store && sku) {
            handleGetAvailabilityBySku(channel, store, sku, isPreorder)
            handleGetStockPoolStockSnapshot(channel, store, sku, isPreorder)
            handleGetStockOutsideStockpoolSnapshot(sku, channel, store, isPreorder)
            handleGetStockPoolReservationValuesSnapshot(sku, channel, store)
        }
    }

    const onSubmit = async () => {
        appContext.displayLoader(true)
        setPreorder(false); // Update preorder state
        setEmptyStockReason('')
        const channel = channelChangeSelection?.value.split(" ")[0].trim() ?? "";
        const store = storeChangeSelection?.value.split(" ")[0].trim() ?? "";
        const sku = skuChangeSelection?.value.split(" ")[0].trim() ?? "";
        let b = false
        if (channel && store && sku) {
            try {
                const data = await getSkuAvailability(authState!, channel, store, sku, false);
                const myTableData = data as SkuAvailabilityTableStructure.ResponseOf<TableSkuAvailability>;

                const dataPreorder = await getSkuAvailability(authState!, channel, store, sku, true);
                const myTableDataPreorder = dataPreorder as SkuAvailabilityTableStructure.ResponseOf<TableSkuAvailability>;

                if ((myTableData.products && !myTableData.products[0].available) && (myTableDataPreorder.products && myTableDataPreorder.products[0].available)) {
                    b = true
                    setPreorder(true); // Update preorder state
                }
            } catch (message) {
                let errorMessage: string;
                if (message instanceof Error) {
                    errorMessage = message.message; // Extract error message
                } else if (typeof message === "string") {
                    errorMessage = message;
                } else {
                    errorMessage = "An unknown error occurred";
                }

                appContext.notifyError(errorMessage);
                setTableData([]);
                appContext.displayLoader(false)
                return; // Exit the function if an error occurs
            }
        }
        handleGetAvailabilityBySku(channel, store, sku, b)
        handleGetStockPoolStockSnapshot(channel, store, sku, b)
        handleGetStockOutsideStockpoolSnapshot(sku, channel, store, b)
        handleGetStockPoolReservationValuesSnapshot(sku, channel, store)

    }

    return (
        <Page title={wordingSkuAvailability.title} descriptionLine1={wordingSkuAvailability.description.line1}
              descriptionLine2={wordingSkuAvailability.description.line2} rightAlignedText={lastUpdateTime}>
            <p className={classes.filterPresentation}>Stock Pool Filter</p>
            <div className={classes.rootFilter}>
                <div className={classes.skuButtonContainer}>

                    <div className={classes.filterItem}>
                        <AsyncAutocompleteSelectComponent
                            id={`edit-channel-sku-availability`}
                            key={`key-channel`}
                            reference={SupportedReference.channel}
                            required={false}
                            information={""}
                            autocompleteDropDownStyle={{'width': '350px'}}
                            label={"Channel"}
                            onSelect={onChannelSelection}
                            defaultValue={channelChangeSelection}
                            onChange={setChannelChangeSelection}
                        />
                    </div>
                    <div className={classes.filterItem}>
                        <AsyncAutocompleteSelectComponent
                            id={`edit-store-sku-availability`}
                            key={`key-store`}
                            reference={SupportedReference.store}
                            required={false}
                            information={""}
                            autocompleteDropDownStyle={{'width': '350px'}}
                            label={"Stock Pool ID"}
                            onSelect={onStoreSelection}
                            defaultValue={storeChangeSelection}
                            onChange={setStoreChangeSelection}
                        />
                    </div>
                    <div className={classes.filterItem}>
                        <AsyncAutocompleteSelectComponent
                            id={`edit-sku-sku-availability`}
                            key={`key-sku`}
                            reference={SupportedReference.sku}
                            required={false}
                            information={""}
                            autocompleteDropDownStyle={{'width': '350px'}}
                            label={"SKU"}
                            onSelect={onSkuSelection}
                            defaultValue={skuChangeSelection}
                            onChange={setSkuChangeSelection}
                        />
                    </div>
                    <UIButton
                        text={wordingSkuAvailability.action.submit.title}
                        color="primary"
                        className={`${classes.saveAction}`}
                        onClick={() => {
                            onSubmit();
                        }}
                        disabled={!allFiltersSet}
                    />
                    <div className={classes.sliderRoot}>
                        <FormGroup>
                            <FormControlLabel
                                name="isPreorder"
                                control={<Switch checked={preorder} onChange={(e) => {
                                    changeCheckedState()
                                }} id={`edit-preorder`}/>}
                                label="Preorder"
                                key="isPreorder"
                                value={preorder}
                            />

                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className={classes.cfoStockPresentation}>
                {emptyStockReason.length !== 0 && (<p>{emptyStockReason}</p>)}
            </div>
            <div className={classes.waitingSelection}>
                {tableData.length === 0 && (<p>Begin by choosing a Stock Pool and a SKU</p>)}
                {tableData.length > 0 && (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>{tableHeaders}</TableRow>
                            </TableHead>
                            <TableBody>{tableRows}</TableBody>
                        </Table>
                    </TableContainer>
                )}
            </div>
            {tableData2.length > 0 && (<p className={classes.filterPresentation}>Detailed Stock Inside Stockpool</p>)}
            {tableData2.length > 0 && (
                <div className={classes.marginTop20}>
                    <div className={classes.waitingSelection}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>{tableHeaders2}</TableRow>
                                </TableHead>
                                <TableBody>{tableRows2}</TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            )}
            {tableData3.length > 0 && (
                <>
                    <p className={classes.filterPresentation}>Detailed Stock Outside Stockpool</p>
                    {isPositiveStockOutsideStockpool ? (
                        <p className={classes.cfoStockPresentation}>Stock is positive. It will not be taken into account
                            in global stock value</p>
                    ) : (
                        <p className={classes.cfoStockPresentation}>Stock is negative. It will be taken into account in
                            global stock value</p>
                    )}
                </>
            )}
            {tableData3.length > 0 && (
                <div className={classes.marginTop20}>
                    <div className={classes.waitingSelection}>
                        <TableContainer
                            component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>{tableHeaders3}</TableRow>
                                </TableHead>
                                <TableBody>{tableRows3}</TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            )}
            {tableData4.length > 0 && (<p className={classes.filterPresentation}>Reservations (Inside Stockpool)</p>)}
            {tableData4.length > 0 && (
                <div className={classes.marginTop20}>
                    <div className={classes.waitingSelection}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>{tableHeaders4}</TableRow>
                                </TableHead>
                                <TableBody>{tableRows4}</TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            )}
        </Page>
    );

};
