export const en = {
    placeholder: {
        dropdown: "Enter 4 characters"
    },
    placeholderOneChar: {
        dropdown: "Enter the first character"
    },
    placeholderTwoChars: {
        dropdown: "Enter first 2 characters"
    },
    errorMessages: {
        loginFailure: 'Connection error'
    },
    prompt: {
        confirmLabel: 'Confirm',
        rejectLabel: 'Cancel',
    },
    login: {
        actions: {
            signIn: 'Sign in',
            signInMS: 'Sign in with Microsoft'
        },
        labels: {
            or: 'OR'
        },
        errors: {
            signIn: 'Sign in failed'
        }
    },
    profile: {
        welcome: 'Welcome'
    },
    datatable: {
        pagination: {
            rowsPerPage: 'Rows per page'
        },
        title: 'Table',
        search: {
            inputPlaceholder: 'Search',
            byLabel: 'by label',
            allCols: 'all columns'
        },
        reorderColumns: {
            modal: {
                title: 'Reorder columns',
                applyButtonLabel: 'Apply',
            },
            triggerButtonLabel: 'Apply reorder',
        },
        changeVisibilityColumns: {
            modal: {
                title: 'Change columns visibility',
                applyButtonLabel: 'Apply',
            },
            triggerButtonLabel: 'Apply change',
        },
        massEdit: {
            toolbar: {
                action: {
                    massEdit: 'MASS EDIT',
                    edit: 'EDIT',
                    closeMassEdit: 'BACK TO LIST',
                }
            },
            dialog: {
                title: 'Mass edit',
                warningMessage: 'row(s) will be updated',
                confirmMessage: 'Do you confirm this edit ?',
                cancelButtonLabel: 'CANCEL',
                applyButtonLabel: 'SUBMIT',
                cancelConfirmButtonLabel: 'NO',
                applyConfirmButtonLabel: 'YES'
            },
            form : {
                field: {
                    label: {
                        selectProperty: 'Field to edit',
                    }
                }
            }
        },
        viewHistory: {
           dialog: {
                title: 'View History'
            }
        },
    },
    page: {
        store: {
            title: 'StockPool ID',
            description: {
                line1: 'Cegid Ecommerce Stores',
                line2: 'Used to Define the StockPool (along with the Channel) and the countries that can impact the stock, out of the stockpool in case of CFO'
            },
            datatable: {
                action: {
                    duplicate: {
                        menuItemLabel: 'Duplicate',
                        confirmationFeedbackMessage: 'Store duplicated with success',
                        modal: {
                            title: 'Duplicate StockPool ID',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        },
                    },
                    remove: {
                        menuItemLabel: 'Delete',
                        confirmationPromptMessage: 'Are you sure you want to delete this StockPool ID ?',
                        confirmedFeedbackMessage: 'StockPool ID deleted',
                    },
                    edit: {
                        menuItemLabel: 'Edit',
                        confirmationFeedbackMessage: 'StockPool ID data updated',
                        modal: {
                            title: 'Edit StockPool ID',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Apply',
                        },
                    },
                    add: {
                        buttonLabel: 'Add new StockPool ID',
                        confirmationFeedbackMessage: 'The new StockPool ID has been successfully added',
                        modal: {
                            title: 'Add new StockPool ID',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        },
                    },
                }
            }
        },
        stocklocation: {
            title: 'Stock Location',
            description: {
                line1: 'Physical CELINE stores information',
                line2: 'Some information are imported from YEXT and are not editable in ALGO'
            },
            datatable: {
                action: {
                    duplicate: {
                        menuItemLabel: 'Duplicate',
                        confirmationFeedbackMessage: 'Stock location duplicated with success',
                        modal: {
                            title: 'Duplicate stock location',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        },
                    },
                    remove: {
                        menuItemLabel: 'Delete',
                        confirmationPromptMessage: 'Are you sure you want to delete this stock location ?',
                        confirmedFeedbackMessage: 'Stock location deleted',
                    },
                    edit: {
                        menuItemLabel: 'Edit',
                        confirmationFeedbackMessage: 'Stock location data updated',
                        modal: {
                            title: 'Edit stock location',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Apply',
                        },
                    },
                    add: {
                        buttonLabel: 'Add new stock location',
                        confirmationFeedbackMessage: 'The new stock location has been successfully added',
                        modal: {
                            title: 'Add new stock location',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        },
                    },
                }
            }
        },
        stocklocationAudit: {
            title: 'Stock Locations History',
            description: {
                line1: 'Physical CELINE stores information updates',
                line2: 'Some information are imported from YEXT and are not editable in ALGO nor tracked in history'
            }
        },
        stockpool: {
            title: 'Stock Pool',
            description: {
                line1: 'List and parameters of CELINE stores that can fulfill an order',
                line2: 'A StockPool is defined by one CHANNEL and one STOCKPOOL ID'
            },
            datatable: {
                action: {
                    duplicate: {
                        menuItemLabel: 'Duplicate',
                        confirmationFeedbackMessage: 'Stock Pool duplicated with success',
                        modal: {
                            title: 'Duplicate Stock Pool',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        },
                    },
                    remove: {
                        menuItemLabel: 'Delete',
                        confirmationPromptMessage: 'Are you sure you want to delete this Stock Pool ?',
                        confirmedFeedbackMessage: 'Stock Pool deleted',
                    },
                    edit: {
                        menuItemLabel: 'Edit',
                        confirmationFeedbackMessage: 'Stock Pool data updated',
                        modal: {
                            title: 'Edit Stock Pool',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Apply',
                        },
                    },
                    add: {
                        buttonLabel: 'Add Stock Location to StockPool',
                        confirmationFeedbackMessage: 'The new Stock Pool has been successfully added',
                        modal: {
                            title: 'Add Stock Location to StockPool',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        },
                    },
                    export: {
                        title: 'EXCEL EXPORT',
                    },
                }
            }
        }, stockpoolaudit: {
            title: 'Stock Pool History',
            description: {
                line1: 'List and parameters of CELINE stores updates that can fulfill an order',
                line2: 'A StockPool is defined by one CHANNEL and one STOCKPOOL ID'
            }
        }, stockLocationRanking: {
            title: 'Stock Location Ranking',
            description: {
                line1: 'Static ranking of Stock Locations inside a StockPool.',
                line2: 'Use to determine allocation priority in case of equality on Stock Locations type and available stock'
            },
            action: {
                save: {
                    title: 'Save',
                    confirmationFeedbackMessage: 'New ranking has been saved',
                    errorFeedbackMessage: 'Could not save ranking'
                }
            }
        },
        threshold: {
            title: 'Threshold',
            description: {
                line1: 'Define a threshold at different levels (by SKU, GNC, Sub-Activity, Stock Pool).',
                line2: 'The availability of a sku is calculated thereby : stock (for a stock pool) – threshold.'
            },
            globalThresholdTitle: 'Global Threshold: ',
            datatable: {
                action: {
                    duplicate: {
                        menuItemLabel: 'Duplicate',
                        confirmationFeedbackMessage: 'Threshold duplicated with success',
                        modal: {
                            title: 'Duplicate Threshold',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        },
                    },
                    remove: {
                        menuItemLabel: 'Delete',
                        confirmationPromptMessage: 'Are you sure you want to delete this Threshold ?',
                        confirmedFeedbackMessage: 'Threshold deleted',
                    },
                    edit: {
                        menuItemLabel: 'Edit',
                        confirmationFeedbackMessage: 'Threshold data updated',
                        modal: {
                            title: 'Edit Threshold',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Apply',
                        },
                    },
                    add: {
                        buttonLabel: 'Add threshold',
                        confirmationFeedbackMessage: 'The new Threshold has been successfully added',
                        modal: {
                            title: 'Add Threshold',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        },
                    },
                }
            },
            thresholdAudit: {
                title: 'Threshold History',
                description: {
                    line1: 'History of Threshold updates at different level (by SKU, GNC, Sub-Activity, Stock Pool).',
                    line2: ''
                }
            }
        },
        pageNotFound: {
            content: 'Page not found',
            backToSite: 'Back to site',
        },
        quota: {
            title: 'Quota',
            description: {
                line1: 'Define a maximum Quota on SKUs for a Zone.',
                line2: 'The Balance of quota will be calculated thereby : Quota Max – Quota Consumption.'
            },
            datatable: {
                action: {
                    add: {
                        buttonLabel: 'Add Quota',
                        confirmationFeedbackMessage: 'The new quota has been successfully added',
                        modal: {
                            title: 'Add Quota',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    }, 
                    edit: {
                        menuItemLabel: 'Edit',
                        confirmationFeedbackMessage: 'Quota data updated',
                        modal: {
                            title: 'Edit Quota',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    },
                    duplicate: {
                        menuItemLabel: 'Duplicate',
                        confirmationFeedbackMessage: 'The new quota has been successfully added',
                        modal: {
                            title: 'Duplicate Quota',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    },
                    remove: {
                        menuItemLabel: 'Delete',
                        confirmationPromptMessage: 'Are you sure you want to delete this Quota ?',
                        confirmedFeedbackMessage: 'Quota deleted',
                    },
                }
            }
        },
        quotaAudit: {
            title: 'Quota History',
            description: {
                line1: 'History of Quota updates.',
                line2: ''
            }
        },
        quotaSkuAudit: {
            title: 'Quota SKU History',
            description: {
                line1: 'History of Quota sku updates.',
                line2: ''
            }
        },
        ccZone: {
            title: 'Click & Collect Zones',
            description: {
                line1: 'Configuration of C&C stores proposed to a client.',
                line2: 'Define a list of countries open to C&C for a given country Id (Client country)'
            },
            datatable: {
                action: {
                    add: {
                        buttonLabel: 'Add Click & Collect Zone',
                        confirmationFeedbackMessage: 'The new click & collect zone has been successfully added',
                        modal: {
                            title: 'Add Click & Collect Zone',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    },
                    edit: {
                        menuItemLabel: 'Edit',
                        confirmationFeedbackMessage: 'Click & Collect Zone data updated',
                        modal: {
                            title: 'Edit Click & Collect Zone',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    },
                    duplicate: {
                        menuItemLabel: 'Duplicate',
                        confirmationFeedbackMessage: 'The new click & collect zone has been successfully added',
                        modal: {
                            title: 'Duplicate Click & Collect Zone',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    },
                    remove: {
                        menuItemLabel: 'Delete',
                        confirmationPromptMessage: 'Are you sure you want to delete this Click & Collect Zone ?',
                        confirmedFeedbackMessage: 'Click & Collect Zone deleted',
                    },
                }
            }
        },
        ccZoneAudit: {
            title: 'C&C Zone History',
            description: {
                line1: '',
                line2: ''
            }
        },
        defaultPPS: {
            title: 'Default Pick Pack Ship Store',
            description: {
                line1: 'Define for each allocation store, the store which will receive the transfer.',
                line2: 'Does not apply to PPS stores.'
            },
            datatable: {
                action: {
                    add: {
                        buttonLabel: 'Add Default PPS',
                        confirmationFeedbackMessage: 'The new default PPS has been successfully added',
                        modal: {
                            title: 'Add Default PPS',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    },
                    edit: {
                        menuItemLabel: 'Edit',
                        confirmationFeedbackMessage: 'Default PPS data updated',
                        modal: {
                            title: 'Edit Default PPS',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    },
                    duplicate: {
                        menuItemLabel: 'Duplicate',
                        confirmationFeedbackMessage: 'The new default PPS has been successfully added',
                        modal: {
                            title: 'Duplicate Default PPS',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    },
                    remove: {
                        menuItemLabel: 'Delete',
                        confirmationPromptMessage: 'Are you sure you want to delete this Default PPS ?',
                        confirmedFeedbackMessage: 'Default PPS deleted',
                    },
                }
            }
        },
        defaultPPSAudit: {
            title: 'Default PPS History',
            description: {
                line1: 'Updates for each allocation store of the store which will receive the transfer.',
                line2: 'Does not apply to PPS stores.'
            }
        },
        skuAvailability: {
            title: 'SKU Availability',
            description: {
                line1: 'Select a Channel, a Stock Pool and a SKU in order to consult product\'s availability',
                line2: ''
            },
            action: {
                save: {
                    title: 'SAVE'
                },
                submit: {
                    title: 'SUBMIT'
                }
            }
        },
        dataSetRequest: {
            title: 'DataSet Requests',
            description: {
                line1: 'Select the request that will extract the dataset wanted',
                line2: ''
            },
            action: {
                save: {
                    title: 'SAVE'
                },
                export: {
                    title: 'EXCEL EXPORT'
                }
            }
        },
        countryDayOff: {
            title: 'Days Off by Country',
            description: {
                line1: 'Days off configuration by country',
                line2: ''
            },
            datatable: {
                action: {
                    add: {
                        buttonLabel: 'Add Country Days Off',
                        confirmationFeedbackMessage: 'The new country day off has been successfully added',
                        modal: {
                            title: 'Add Country Days Off',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    },
                    edit: {
                        menuItemLabel: 'Edit',
                        confirmationFeedbackMessage: ' Country Day Off data updated',
                        modal: {
                            title: 'Edit Country Day Off',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    },
                    duplicate: {
                        menuItemLabel: 'Duplicate',
                        confirmationFeedbackMessage: 'The new country day off has been successfully added',
                        modal: {
                            title: 'Duplicate Country Day Off',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    },
                    remove: {
                        menuItemLabel: 'Delete',
                        confirmationPromptMessage: 'Are you sure you want to delete this Country Day Off ?',
                        confirmedFeedbackMessage: 'Country Day Off deleted',
                    },
                }
            }
        },
        transporterDaysOffByCountry: {
            title: 'Transporter Days Off by Country',
            description: {
                line1: 'Transporter and transporter Days Off by Country. ',
                line2: 'Transporter code and Country code are mandatory and must be set in order that Algo works with this country'
            },
            datatable: {
                action: {
                    add: {
                        buttonLabel: 'Add Transporter Days Off By Country',
                        confirmationFeedbackMessage: 'The new transporter days off by country has been successfully added',
                        modal: {
                            title: 'Add Transporter Days Off By Country',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    },
                    edit: {
                        menuItemLabel: 'Edit',
                        confirmationFeedbackMessage: 'Transporter Days Off By Country data updated',
                        modal: {
                            title: 'Edit Transporter Days Off By Country',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    },
                    duplicate: {
                        menuItemLabel: 'Duplicate',
                        confirmationFeedbackMessage: 'The new transporter days off by country has been successfully added',
                        modal: {
                            title: 'Duplicate Transporter Days Off By Country',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    },
                    remove: {
                        menuItemLabel: 'Delete',
                        confirmationPromptMessage: 'Are you sure you want to delete this Transporter Days Off By Country ?',
                        confirmedFeedbackMessage: 'Transporter Days Off By Country deleted',
                    },
                }
            }
        },
        transporterDaysOffWeek: {
            title: 'Transporter Days Off Week',
            description: {
                line1: 'Setting of Transporter code and Transporter Days off week.',
                line2: 'Transporter code is mandatory in order that Algo responds with an Allocation'
            },
            datatable: {
                action: {
                    add: {
                        buttonLabel: 'Add Transporter Days Off Week',
                        confirmationFeedbackMessage: 'The new transporter days off week has been successfully added',
                        modal: {
                            title: 'Add Transporter Days Off Week',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    },
                    edit: {
                        menuItemLabel: 'Edit',
                        confirmationFeedbackMessage: 'Transporter Days Off Week data updated',
                        modal: {
                            title: 'Edit Transporter Days Off Week',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    },
                    duplicate: {
                        menuItemLabel: 'Duplicate',
                        confirmationFeedbackMessage: 'The new transporter days off week has been successfully added',
                        modal: {
                            title: 'Duplicate Transporter Days Off Week',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    },
                    remove: {
                        menuItemLabel: 'Delete',
                        confirmationPromptMessage: 'Are you sure you want to delete this Transporter Days Off Week ?',
                        confirmedFeedbackMessage: 'Transporter Days Off Week deleted',
                    },
                }
            }
        },
        transferDelay: {
            title: 'Transfer Delay',
            description: {
                line1: 'Define a Specific Transfer Delay between an allocation point and a destinated Stock Location',
                line2: 'Default Transfer Delay is 2 Days'
            },
            datatable: {
                action: {
                    add: {
                        buttonLabel: 'Add Transfer Delay',
                        confirmationFeedbackMessage: 'The new transfer delay has been successfully added',
                        modal: {
                            title: 'Add Transfer Delay',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    },
                    edit: {
                        menuItemLabel: 'Edit',
                        confirmationFeedbackMessage: 'Transfer Delay updated',
                        modal: {
                            title: 'Edit Transfer Delay',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    },
                    remove: {
                        menuItemLabel: 'Delete',
                        confirmationPromptMessage: 'Are you sure you want to delete this Transfer Delay ?',
                        confirmedFeedbackMessage: 'Transfer Delay deleted',
                    },
                }
            }
        },
        preorderConfig: {
            title: 'MTO allocation',
            description: {
                line1: 'There is no stock for MTO, we set a default allocation for each stockpool/channel',
                line2: ''
            },
            datatable: {
                action: {
                    add: {
                        buttonLabel: 'ADD A NEW STOCKPOOL SETUP FOR MTO',
                        confirmationFeedbackMessage: 'The new MTO config has been successfully added',
                        modal: {
                            title: 'Add ',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    },
                    duplicate: {
                        menuItemLabel: 'Duplicate',
                        confirmationFeedbackMessage: 'The new MTO config has been successfully added',
                        modal: {
                            title: 'Duplicate',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    },
                    edit: {
                        menuItemLabel: 'Edit ',
                        confirmationFeedbackMessage: 'MTO Configuration updated',
                        modal: {
                            title: 'Edit MTO Configuration',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    },
                    remove: {
                        menuItemLabel: 'Delete',
                        confirmationPromptMessage: 'Are you sure you want to delete this MTO Configuration ?',
                        confirmedFeedbackMessage: 'MTO Configuration deleted',
                    },
                }
            }
        },
        preorderConfigAudit: {
            title: 'MTO Configuration History',
            description: {
                line1: '',
                line2: ''
            }
        },
        deliveryMethodEligibility: {
            title: 'Delivery Method',
            description: {
                line1: 'List shipping methods available for a country per channel',
                line2: ''
            },
            datatable: {
                action: {
                    add: {
                        buttonLabel: 'ADD A NEW DELIVERY METHOD FOR A COUNTRY AND CHANNEL',
                        confirmationFeedbackMessage: 'The new delivery method configuration has been successfully added',
                        modal: {
                            title: 'Add ',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    },
                    duplicate: {
                        menuItemLabel: 'Duplicate',
                        confirmationFeedbackMessage: 'The new delivery method configuration has been successfully added',
                        modal: {
                            title: 'Duplicate',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    },
                    edit: {
                        menuItemLabel: 'Edit ',
                        confirmationFeedbackMessage: 'Delivery method configuration updated',
                        modal: {
                            title: 'Edit Delivery Method Configuration',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    },
                    remove: {
                        menuItemLabel: 'Delete',
                        confirmationPromptMessage: 'Are you sure you want to delete this delivery method configuration ?',
                        confirmedFeedbackMessage: 'Delivery Method Configuration deleted',
                    },
                }
            }
        },
        deliveryMethodEligibilityAudit: {
            title: 'Delivery Method Eligibility History',
            description: {
                line1: '',
                line2: ''
            }
        },
        shipmentMatrix: {
            title: 'Shipment Matrix',
            description: {
                line1: 'Shipment Matrix defines a shipment time by Transporter, Shipment Method and from a stock location to a Zip Code.',
                line2: 'When no configuration is found, shipment time is 3 days by default for EXPRESS and STANDARD delivery method.'
            },
            modal: {
                successMessage: 'The shipment matrix is well updated',
                successTitle: 'SUCCESS',
                errorTitle: 'ERROR',
                close: 'CLOSE',
            },
            datatable: {
                action: {
                    add: {
                        buttonLabel: 'Add Shipment Matrix',
                        confirmationFeedbackMessage: 'The new shipment matrix has been successfully added',
                        modal: {
                            title: 'Add Shipment Matrix',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    },
                    edit: {
                        menuItemLabel: 'Edit',
                        confirmationFeedbackMessage: 'Shipment Matrix data updated',
                        modal: {
                            title: 'Edit Shipment Matrix',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    },
                    duplicate: {
                        menuItemLabel: 'Duplicate',
                        confirmationFeedbackMessage: 'The new shipment matrix has been successfully added',
                        modal: {
                            title: 'Duplicate Shipment Matrix',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    },
                    remove: {
                        menuItemLabel: 'Delete',
                        confirmationPromptMessage: 'Are you sure you want to delete this Shipment Matrix ?',
                        confirmedFeedbackMessage: 'Shipment Matrix deleted',
                    },
                    export: {
                        title: 'CSV EXPORT',
                    },
                    import: {
                        title: 'CSV IMPORT',
                        confirmTitle: 'Warning',
                        text: 'The whole shipment matrix will be replaced by these new data. Ensure that you exported a backup before. Do you want to continue?',
                        confirm: 'CONFIRM',
                        cancel: 'CANCEL',
                    },
                }
            }
        },
        promiseDateMargin: {
            title: 'Promise Date Margin',
            description: {
                line1: 'Define a margin (in days) in order to calculate a Client Promise Date.',
                line2: 'Promise Date Margin is defined by Shipment Method, Country and Channel.'
            },
            datatable: {
                action: {
                    add: {
                        buttonLabel: 'Add Promise Date Margin',
                        confirmationFeedbackMessage: 'The new Promise Date Margin has been successfully added',
                        modal: {
                            title: 'Add Promise Date Margin',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    },
                    edit: {
                        menuItemLabel: 'Edit',
                        confirmationFeedbackMessage: 'Promise Date Margin updated',
                        modal: {
                            title: 'Edit Promise Date Margin',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    },
                    remove: {
                        menuItemLabel: 'Delete',
                        confirmationPromptMessage: 'Are you sure you want to delete this Promise Date Margin ?',
                        confirmedFeedbackMessage: 'Promise Date Margin deleted',
                    },
                }
            }
        },
        defaultLeadTime: {
            title: 'Default Lead time',
            description: {
                line1: 'Define a default lead time in days (not mandatory). ',
                line2: 'Lead time service checks first if a default Lead Time is defined. If not, Preparation Time and Shipment time rules are applied.'
            },
            datatable: {
                action: {
                    add: {
                        buttonLabel: 'Add Default Lead Time',
                        confirmationFeedbackMessage: 'The new Default Lead Time has been successfully added',
                        modal: {
                            title: 'Add Default Lead Time',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    },
                    edit: {
                        menuItemLabel: 'Edit',
                        confirmationFeedbackMessage: 'Default Lead Time updated',
                        modal: {
                            title: 'Edit Default Lead Time',
                            cancelButtonLabel: 'Cancel',
                            applyButtonLabel: 'Submit',
                            applyAndResetLabel: 'Submit and continue',
                        }
                    },
                    remove: {
                        menuItemLabel: 'Delete',
                        confirmationPromptMessage: 'Are you sure you want to delete this Default Lead Time ?',
                        confirmedFeedbackMessage: 'Default Lead Time deleted',
                    },
                }
            }
        },
        subzone: {
            title: 'Subzone',
            description: {
                line1: 'Define subzones',
                line2: 'NB : a subzone is assigned to each stock location of a stockpool and is used as a sorting criteria in allocation',
                line3: 'Define a list of countries or a list or states for each subzone'
            },
            datatable: {
                subzone: {
                    title: 'SUBZONE',
                    action: {
                        add: {
                            buttonLabel: 'ADD SUBZONE',
                            confirmationFeedbackMessage: 'The new Subzone has been successfully added',
                            modal: {
                                title: 'Add Subzone',
                                cancelButtonLabel: 'Cancel',
                                applyButtonLabel: 'Submit',
                                applyAndResetLabel: 'Submit and continue',
                            }
                        },
                        edit: {
                            menuItemLabel: 'Edit',
                            confirmationFeedbackMessage: 'Subzone updated',
                            modal: {
                                title: 'Edit Subzone',
                                cancelButtonLabel: 'Cancel',
                                applyButtonLabel: 'Submit',
                                applyAndResetLabel: 'Submit and continue',
                            }
                        },
                        remove: {
                            menuItemLabel: 'Delete',
                            confirmationPromptMessage: 'Are you sure you want to delete this subzone ?',
                            confirmedFeedbackMessage: 'Subzone deleted',
                        },
                    }
                },
                country: {
                    title: 'COUNTRIES BY SUBZONE',
                    action: {
                        add: {
                            buttonLabel: 'ADD COUNTRY',
                            confirmationFeedbackMessage: 'The new Subzone Country has been successfully added',
                            modal: {
                                title: 'Add Subzone Country',
                                cancelButtonLabel: 'Cancel',
                                applyButtonLabel: 'Submit',
                                applyAndResetLabel: 'Submit and continue',
                            }
                        },
                        edit: {
                            menuItemLabel: 'Edit',
                            confirmationFeedbackMessage: 'Subzone Country updated',
                            modal: {
                                title: 'Edit Subzone Country',
                                cancelButtonLabel: 'Cancel',
                                applyButtonLabel: 'Submit',
                                applyAndResetLabel: 'Submit and continue',
                            }
                        },
                        remove: {
                            menuItemLabel: 'Delete',
                            confirmationPromptMessage: 'Are you sure you want to delete this Subzone Country ?',
                            confirmedFeedbackMessage: 'Subzone deleted',
                        },
                    }
                },
                state: {
                    title: 'STATES BY SUBZONE',
                    action: {
                        add: {
                            buttonLabel: 'ADD STATE',
                            confirmationFeedbackMessage: 'The new Subzone State has been successfully added',
                            modal: {
                                title: 'Add Subzone State',
                                cancelButtonLabel: 'Cancel',
                                applyButtonLabel: 'Submit',
                                applyAndResetLabel: 'Submit and continue',
                            }
                        },
                        edit: {
                            menuItemLabel: 'Edit',
                            confirmationFeedbackMessage: 'Subzone State updated',
                            modal: {
                                title: 'Edit Subzone State',
                                cancelButtonLabel: 'Cancel',
                                applyButtonLabel: 'Submit',
                                applyAndResetLabel: 'Submit and continue',
                            }
                        },
                        remove: {
                            menuItemLabel: 'Delete',
                            confirmationPromptMessage: 'Are you sure you want to delete this Subzone State ?',
                            confirmedFeedbackMessage: 'Subzone State deleted',
                        },
                    }
                }
            }
        },
        subzoneAudit: {
            title: 'Subzone History',
            description: {
                line1: '',
                line2: ''
            }
        },
        subzoneCountryAudit: {
            title: 'Subzone Country History',
            description: {
                line1: '',
                line2: ''
            }
        },
        subzoneStateAudit: {
            title: 'Subzone State History',
            description: {
                line1: '',
                line2: ''
            }
        },
    }
};
